* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Droid Sans",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  min-width: 340px;
  max-width: 2560px;
  margin: auto;
}

.icon, .logo {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
}

button {
  border: 0;
  transition: all .3s;
}

a {
  color: inherit;
  transition: all .3s ease 0s;
  text-decoration: none;
}

.page-wrapper a:hover,
.page-wrapper a:active,
{ color: #326bd2; }

hr {
  margin: 2rem 1rem;
  border: 1px solid #ddd;
}

.grid > .col-6 {
  display: inline-block;
  vertical-align: top;
  margin: 0 1rem;
  width: calc(100% - 2rem);
  @media (min-width: 801px) {
    padding: 1rem;
    margin: 0 1rem;
    width: calc(50% - 2rem - 4px);
    &.seperator {
      @include seperator;
      padding-left: 2rem;
      // margin-left: .5rem;
    }
  }
}

.page-header {
  text-align: center;
  position: absolute;
  margin-top: -6rem;
  width: 100%;
  padding: .5rem;
  color: white;
  .heading {
    margin: 0;
  }
  .subheading {
    margin-top: 0.2rem;
    padding: .25rem .5rem;
    background-color: rgba(38,49,56,0.5);
    // color: white;
    z-index: 1;
    display: inline-block;
    border-radius: 0.4rem;
  }
}

.page-summary {
  padding: 2rem 1rem;
  line-height: 1.5rem;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto 2rem;
  font-size: 1.2rem;
  border-bottom: 1px solid #ddd;
}

body:not(.home) main {
  position: relative;
  // max-width: 1000px;
  margin: auto;
  margin-bottom: 4rem;
  .page-wrapper {
    max-width: 1000px;
    margin: auto;
  }
}

.home main {
  margin-top: 420px;
  overflow: hidden;
}

.page-bg {
  width: 100%;
  height: 240px;
}

.courses .page-bg { background-image: url(../images/banners/courses.jpg); }
.students .page-bg { background-image: url(../images/banners/students.png); }
.contact .page-bg { background-image: url(../images/banners/contactt.png); }
.special_courses .page-bg { background-image: url(../images/banners/dev.jpg); }
