body.students {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex-grow: 1;
    margin: 0;
    .page-wrapper {
      padding: 1rem;
    }
  }
  margin: 0;
  h3 {
    border-bottom: 1px solid gray;
    margin-bottom: 0.1rem;

  }

  a {
    color: #326bd2;
  }

  a:active, a:hover {
    color: #1c438a;
  }
}

.stmedia {
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    > h4 {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  div:nth-child(even) { background: #ddd }
  div:nth-child(odd) { background: #f0f0f0 }
}

#classcodestatus { display: inline-block; width: 28px; height: 28px; }
#classcodeformcont form { text-align: center; }

.classtoc {
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #f9f9f9;

  summary {
    padding: 0 1rem;
  }
  h2 {
    display: inline-block;
  }
  ul {
    margin-top: 0;
  }

}

.stlinks {
  li {
    list-style-type: circle;
    margin: 0.5rem;
  }
  li span {
    color: gray;
    display: block;
  }

}

div.lm {
  font-size: 0.7rem;
  color: #999;
  font-weight: normal;
}


@media (max-width: 480px) { 
  div.stmedia > div {
    flex-direction: column;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    > h4 {
      margin-bottom: 0.4rem;
    }
  }
}



.classmedia details summary {
  font-weight: bold;
  font-size: 1.1em;
  padding: 0.6em 0.5em;
  background-color: #e9f8e9;
  margin-top: 0.3em;
  transition: 0.5s;
  border-radius: 0.2em 0.2em 0 0;
  cursor: pointer;
}

.classmedia details summary:hover,
.classmedia details[open] summary {
  background-color: #b8ffb8;
}


.ar {
  text-align: right;
}

.ltop {
  font-weight: bold;
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.ltopcontainer {
  padding: 1rem;
}
