@mixin seperator {
  position: relative;
  border-left: 2px solid #ddd;
  &:before, &:after {
    background-color: #ddd;
    border-radius: 5px;
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    left: -7px;
  }

  &:before {
    top: 0
  }

  &:after {
    bottom: 0;
  }
}
