.home-courses {
  .title {
    text-align: center;
    text-transform: uppercase;
    padding: 2rem .5rem;
    margin: 0;
    font-family: fantasy;
    font-size: 1.75rem;
    font-weight: normal;
  }

  nav {
    text-align: center;
    overflow: hidden;
    a {
      font-size: 1.25rem;
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      text-transform: uppercase;
      font-family: monospace;
      padding: .5rem 2.5%;
      @media (max-width: 768px) {
        width: 47.5%;
        &:first-child, &:nth-child(3) {
          text-align: right;
          img { margin: auto 0 .5rem auto; }
        }
        &:nth-child(2), &:last-child {
          text-align: left;
          img { margin: auto auto .5rem 0; }
        }
      }

      @media (max-width: 480px) {
        width: 80%;
        text-align: center !important;
        img { margin: auto !important; }
      }

      img {
        vertical-align: text-top;
        padding: 0;
        height: 50px;
        display: block;
        margin: auto auto .5rem;
        filter: gray; /* IE6-9 */
        filter: grayscale(.5); /* Microsoft Edge and Firefox 35+ */
        -webkit-filter: grayscale(.5);
        transition: all .3s ease 0s;
      }

      &:hover, &:focus {
        transform: scale(1.1) /* skew(-5deg) rotate(-1deg)*/;
        img {
          filter: none;
          -webkit-filter: none;
        }
      }
    }
  }

  p {
    width: 80%;
    max-width: 768px;
    margin: 0 auto;
    font-size: 1.05rem;
    text-align: center;
    padding: 2em .5em;
    font-weight: bold;
    line-height: 1.5rem;
    /*text-shadow: 0 0 0 black;*/
  }
}

.home-features {
  padding-top: 2rem;
  max-width: 80%;
  margin: auto;
  line-height: 1.5rem;

  .virtues {
    padding: .5rem;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        position: relative;
        padding: 13px 5px 14px 44px;
        // max-width: 480px;
        font-size: .95em;
        &.collapsed:before {
          // opacity: 0;
          transform: scale(.1);
          filter: grayscale(1);
        }
        &:before {
          content: '';
          background-image: url('../images/checkmark.svg');
          background-position: 0;
          background-repeat: no-repeat;
          width: 32px;
          height: 32px;
          position: absolute;
          left: 0;
          top: calc(50% - 16px);
          transition: all 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) .3s;
          transform: none;
          filter: none;
          -webkit-filter: none;
        }
      }
    }
  }
  .more-info {
    @include seperator;
    font-size: .95rem;
    padding: .5rem .5rem .5rem 5rem;
    @media (max-width: 800px) {
      margin: 2rem 1rem 1rem;
    }
    article {
      position: relative;
      margin-bottom: 5rem;
      .icon {
        width: 3rem;
        height: 3rem;
        position: absolute;
        margin-left: -3.75rem;
        color: rgba(black, .95);
      }
      h4 {
        font-size: 1rem;
        font-family: serif;
        margin-bottom: .5rem;
        & + p {
          margin-top: .5rem;
        }
      }

      .button {
        @media (max-width: 480px) {
          width: 70%;
        }
        min-width: 40%;
        float: right;
        opacity: .9;
        padding: .5rem 1rem;
        text-align: center;
        border-radius: 4px;
        // transition: opacity .3s ease 0s;
        /*border-radius: 0;*/
        &:hover, &:focus {
          opacity: 1;
        }
      }
    }
  }

  .quote {
    position: relative;
    overflow: hidden;
    font-size: 0.95rem;
    margin-top: 2rem;
    transition: 1s all;
    &.collapsed {
      transform: translateY(100%);
    }
    blockquote {
      font-size: 100%;
      quotes: "“" "”";
      border-radius: 2px 2px 0 0;
      position: relative;
      padding: 1rem 2rem 3rem;
      margin: 0 auto;
      max-width: 800px;
      &:before {
        content: open-quote;
        font-size: 4em;
        position: absolute;
        line-height: 1;
        // top: 20px;
        left: .5rem;
        font-family: serif;
      }
      &:after {
        content: close-quote;
        font-size: 4em;
        position: absolute;
        line-height: 0;
        // bottom: 10spx;
        right: .5rem;
        font-family: serif;
      }
      p {
        margin: .5rem;
        line-height: 1.5rem;
      }
    }
    cite {
      float: right;
      margin: 0 1rem;
      font-size: 1.1rem;
    }

  }
}