.table {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 3rem;
  padding: 0;
  max-width: 800px;
}
.table-cell {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.8rem 1.2rem;
  overflow: hidden;
  list-style: none;
  border: solid 1px #e2e6e9;
  h1, h2, h3, h4, h5, h6 { margin: 0; }
}

.table-cell--head {
  // color: white;
  font-size: 1.1rem;
  &.hide {
    background: transparent !important;
    font-size: 0;
  }
}

.table-cell--foot {
  background-color: #b8c0c8;
  border-color: #a9b3bc;
}


/* Table column sizing
================================== */
.table--2cols > .table-cell {
  width: 50%;
}
.table--3cols > .table-cell {
  width: 33.33%;
}
.table--4cols > .table-cell {
  width: 25%;
}
.table--5cols > .table-cell {
  width: 20%;
}
.table--6cols > .table-cell {
  width: 16.6%;
}


/* Responsive
==================================== */
@media all and (max-width: 800px) {
  .table--collapse {
    display: block;
  }
  .table--collapse > .table-cell {
    width: 100% !important;
  }
  .table--collapse > .table-cell--foot {
    margin-bottom: 1rem;
  }
  .table-cell {
    &.empty {
      display: none;
    }
    &.m-hide, &.hide {
      display: none;
    }
    .pre {
      display: inline-block;
      margin: .25rem .5rem .25rem 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #f5f5f5;
      &:before {
        content: attr(data-content);
      }
    }
  }
}

.table-cell.m-hide {
  background-color: #f5f5f5;
  .pre { display: none; }
  .pre + .pre {
    display: block;
    width: 100%;
    white-space: nowrap;
  }
}

.pre {
  display: none;
  padding: .5rem;
  margin: auto;
  white-space: pre-line;
  font-weight: bold;
  font-family: monospace;
  line-height: 1.5;
}
