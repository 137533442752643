.not-found .page-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  h1 {
    font-weight: bold;
    text-align: center;
    font-size: 10rem;
    margin: 0 1rem;
    height: 10rem;
    line-height: 10rem;
    color: gray;
  }
  p {
    font-size: 2rem;
  }
  a {
    max-width: 20rem;
    margin: 2rem auto;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}