@charset "utf-8";

@import "normalize";

@import "mixins";

@import "themes/base";
// @import 'themes/green';
// @include themestyles();
@import 'themes/blue';
@include themestyles();
// @import 'themes/pink';
// @include themestyles();

@import "global";
@import "404";

@import "header";
@import "footer";

@import "home";
@import "courses";
@import "contact";
@import "students";

@import "table";
@import "zSlider";
