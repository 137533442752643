.page-course {

  .course-title {
    text-align: center;
    color: #111;
    margin-bottom: 2rem;
    img {
      // filter: invert(1);
      width: 2rem;
      height: 2rem;
      margin-right: .75rem;
      vertical-align: text-bottom;
    }
  }

  .level-title {
    padding: .5rem 1rem;
    display: inline-block;
    font-weight: normal;
    border-radius: 4px;
    margin-bottom: 0;
  }
  .level-tag {
    float: right;
    background-color: #ffeb8d;
    display: inline-block;
    padding: .5rem;
    font-size: .9rem;
    transform: rotate(7deg) translateY(1rem);
  }
  .level-text {
    line-height: 1.5rem;
  }
  .course-note {
    text-align: center;
    margin: 1rem;
    // display: inline-block;
    padding: .5rem 1rem;
    background-color: rgba(black, .05);
  }
  .degrees-title {
    margin: 1rem auto 0;
    padding: .5rem;
    max-width: 800px;
    // border-bottom: 1px solid #ddd;
    font-weight: normal;
    font-size: 1.2rem;
    .icon {
      width: 2.5rem;
      height: 2.5rem;
      vertical-align: middle;
      margin-right: .5rem;
    }
  }
}