.contact {
  .button {
    position: relative;
    padding: .5rem 1rem;
    // font-weight: bold;
    font-size: .95rem;
    border-radius: 4px;
    opacity: .9;
    display: inline-block;
    margin: auto .5rem;
    @media (max-width: 1000px) {
      min-width: 80%;
      & + .button {
        margin-top: 1rem;
      }
    }
    &.with-icon {
      padding-left: 2.5rem;
    }
    &:hover, &:focus {
      opacity: 1;
    }
  }
  hr {
    margin: 1rem;
  }
}

.contact-address {
  max-width: 340px;
  margin: auto;
  dt, dd {
    margin: 0;
    line-height: 1.5rem;
  }
  dt {
    font-weight: bold;
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: sub;
      margin-right: .25rem;
    }
  }
  dd {
    margin-bottom: 1rem;
    font-size: 1.05rem;
    &.monospace {
      font-family: monospace;
      font-size: 1.25rem;
    }
  }
}

.contact-map {
  text-align: center;
  .leaflet-map {
    border: 1px solid #ddd;
    width: calc(100% - 2rem);
    height: 320px;
    margin: auto auto 1rem;
  }
}

.contact-links {
  list-style-type: none;
  padding: 0;
  text-align: center;

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 0;
    top: calc(50% - 1rem);
  }
}

.contact-form {
  fieldset {
    border: 1px solid #ddd;
    position: relative;
    text-align: center;
  }
  legend {
    font-family: serif;
    font-size: 1.25rem;
    color: #555;
    border-radius: 2px;
    position: relative;
    text-transform: uppercase;
    // font-weight: bold;
    &:before {
      content: attr(data-content);
      position: absolute;
      overflow: hidden;
      height: 50%;
      z-index: 1;
    }
  }
  label.required:after, .form-note:before {
    content: '*';
    color: #b22222;
    margin: 0 .25rem;
  }
  label {
    display: block;
    margin-top: 1rem;
    text-align: left;
    color: #222;
    font-size: 1rem;
  }
  .form-note {
    width: 100%;
    color: #555;
    font-size: .95rem;
    margin: 1rem 0;
  }
  input, textarea {
    display: block;
    width: 100%;
    resize: vertical;
    font-size: 1rem;
    margin: .25rem 0 .5rem;
    padding: .5rem;
    border: 1px solid #ddd;
    box-shadow: 0 0 1px rgba(0,0,0,.075) inset;
    color: #222;
  }
  button[type=submit] {
    margin: .5rem 0 1rem;
    border-radius: 100%;
    height: 6rem;
    width: 6rem;
    padding: .5rem;
    font-size: 1rem;
    opacity: .9;
    // font-weight: bold;
    // text-shadow: 0 0 1px #222;
    cursor: pointer;
    &:hover, &:focus {
      opacity: 1;
    }
  }
  [data-selector] { font-weight: bold; text-align: center; font-size: .95rem; }
  [data-selector=success_msg] { color: green; }
  [data-selector=error_msg] { color: red; }
}
