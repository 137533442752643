$theme-name: 'blue';

$dark-color: black;
$light-color: white;
$sep-color: #ddd;

$leading-color: desaturate(darken(cornflowerblue, 15%), 15%);
$quote-color: darkslateblue;
$quote-color-fg: $light-color;

$header-color: #151d21;
$nav-color: #263138;
