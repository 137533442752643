
@mixin themestyles() {
  body.#{$theme-name} {
    .site-header {
      .navigation {
        background-color: rgba($nav-color, .85);
        color: $light-color;
      }
      .navigation-toggle {
        background-color: $header-color;
        box-shadow: 2px 1px 4px -1px $header-color;
      }
    }
    .z-slide-indicator .z-slide-dot.active {
      background-color: $leading-color;
    }
    .home-courses {
      background-color: $leading-color;
      color: $light-color;
      .title {
        text-shadow: 0 0 1px darken($leading-color, 50%);
      }
    }
    .home-features {
      .quote blockquote {
        background-color: $quote-color;
        color: $quote-color-fg;
        &:before, &:after {
          color: rgba($quote-color-fg, .85);
        }
      }
      .more-info .button {
        background-color: $leading-color;
        color: $light-color;
      }
    }

    .level-title {
      background-color: $leading-color;
      color: $light-color;
    }
    .level-tag {
      background-color: $quote-color;
      color: $quote-color-fg;
    }
    .table-cell--head {
      background: lighten(desaturate($leading-color, 5%), 45%);
    }

    .contact-map .button {
      background-color: $leading-color;
      color: $light-color;
    }
    .contact-form {
      // background-color: rgba($leading-color, .05);
      legend:before {
        color: $leading-color;
      }
      button[type=submit] {
        background-color: $quote-color;
        color: $quote-color-fg;
        border: .1rem solid $sep-color;
      }
    }

    .site-footer {
      background-color: $header-color;
    }
  }
}
