.site-header {
  display: flex;
  flex-direction: column;
  color: white;
  background-size: cover;
  background-position: center center;
  // height: 240px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
  & > * { transition: all .3s; }
  &.collapsed {
    .navigation, .contact-info { transform: translateY(-2.2rem); }
    .navigation-toggle { transform: translateY(-1.7rem); }
  }
  
  @media (max-width: 480px) { &.collapsed .navigation { transform: translateY(-2.8rem); } }
  
  .brand {
    // margin: 2rem 1rem 1rem 3rem;
    // order: 1;
    // align-self: flex-start;
    position: absolute;
    top: 2.25rem;
    left: 4rem;
    img {
      width: 220px;
    }
  }

  .navigation {
    width: 100%;
    text-align: right;
    padding: 0 6rem 0 0;
    font-size: 0;
    z-index: 99;
    .icon {
      vertical-align: bottom;
    }
    a {
      font-size: .95rem;
      padding: .5rem 1rem;
      display: inline-block;
      &:hover, &:focus {
        background-color: #eee;
        color: #111;
      }
    }

    @media (max-width: 480px) {
      a {
        font-size: 0;
        .icon { width: 2rem; height: 2rem; }
      }
    }
  }

  .navigation-toggle {
    position: absolute;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    top: 1.5rem;
    color: rgba(white, .85);
    border-radius: 4px;
    cursor: pointer;
    &:hover, &:focus { color: white; }
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    z-index: 999;
  }

  .contact-info {
    position: absolute;
    top: 2.2rem;
    right: 5rem;
    margin: 0;
    background-color: rgba(38,49,56,.5);
    color: #ddd;
    height: 1.75rem;
    line-height: 1.75rem;
    padding: 0 1rem;
    border-radius: 0 0 0 2px;
    .icon {
      vertical-align: middle;
      margin-top: -.25rem;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
}
