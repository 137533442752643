.z-slide-wrap {
  position: relative;
  display: block;
  height: 180px;
}
.z-slide-content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.z-slide-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  &.hide {
    opacity: 0;
  }
}
.z-slide-item > a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.z-slide-item img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  color: white;
}
.z-slide-indicator {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  z-index: 10;
}
.z-slide-indicator .z-slide-dot {
  display: block;
  float: left;
  width: 1rem;
  height: 1rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border-radius: 50%;
  background-color: #fff;
}

.z-slide-indicator {
  bottom: 1rem;
  transition: .5s all;
  right: 1rem;
  left: auto !important;
  @media (max-width: 801px) {
    display: none;
  }
  .z-slide-dot {
    border: 2px solid #aaa;
    background-color: white;
  }
}

.z-slide-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 420px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    .hidden { display: none; }
  }
  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(white, .5);
    padding: 1rem;
    padding-top: .5rem;
    .title {
      font-size: 3rem;
    }
    .subtitle {
      text-transform: uppercase;
      margin: .5rem 0;
      font-size: 1.5rem;
    }
    .text {
      font-size: 1.25rem;
    }
  }
}
