.site-footer {
  position: relative;
  overflow: hidden;
  display: flex;
  color: rgba(white, .8);
  min-height: 6rem;
  align-items: center;
  justify-content: space-around;
  a:hover, &:focus {
    color: white;
  }

  .navigation {
    order: 1;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: initial;
    .navigation {
      order: 0;
      margin: 2rem .5rem;
    }
    .copyright {
      margin-bottom: 3rem;
    }
  }

  .logo {
    transition: all .3s ease 0s;
    opacity: .9;
    &:hover, &:focus {
      opacity: 1;
    }
    &.ron {
      width: 4.5rem;
      height: 2rem;
      position: absolute;
      bottom: -1px;
      left: calc(50% - 2rem);
    }
    &.fb {
      width: 2rem;
      height: 2rem;
      margin: 0 .25rem;
      vertical-align: middle;
    }
  }
}